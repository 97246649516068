html,
body{
  overflow: auto;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  background-color: #fff;
  height: 100%;
}

#root {
  height: 100%;
}

html {
  /* 1rem = 10px */
  font-size: 62.5%;
}

html * {
  box-sizing: inherit;
}

body {
  font-size: 1.6rem;
  margin: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number]{
    -moz-appearance: textfield;
}
#footer{
  height: env(safe-area-inset-bottom);
}

.form-check-input:checked {
    background-color: #2BA6CB;
    border-color: #2BA6CB;
}
/* modals */
.modal-content {
  border-radius: 1rem !important;
}

.map-container {
  width: 100%;
  height: 100%;
}

.primary_button {
  color: #fff;
  border-color: transparent;
  border-radius: 4px;
  border: 8px;
  background: linear-gradient(180deg, #2ba6cb 0%, #1988a9 100%);
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-size: 12px;
  font-weight: bold;
  line-height: 100%;
  margin: 1rem;
  padding: 1rem;
  width: 8rem;
}

.secondary_button {
  border-color: #1988a9;
  border-radius: 4px;
  border: 1px solid;
  background: #fff;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-size: 12px;
  font-weight: bold;
  line-height: 100%;
  color: #1988a9;
  margin: 1rem;
  padding: 1rem;
  width: 8rem;
}